/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { User } from '../models';
import { CURRENT_USER } from '../graphql/queries';
import Loading from '../components/shared/Loading';
import AppContext from './index';

interface AppContextProvider {
  children: React.ReactNode;
}

function AppContextProvider({ children }: AppContextProvider) {
  const router = useRouter();
  const {
    loading: loadingUser,
    data: dataUser,
    error: errorUser,
  } = useQuery<{
    me: User;
  }>(CURRENT_USER);

  const [user, setUser] = React.useState<User>({});
  React.useEffect(() => {
    if (!loadingUser) {
      if (dataUser) {
        setUser(dataUser.me);
      }
      if (errorUser) {
        setUser(null);
      }
    }
  }, [loadingUser, dataUser, errorUser]);

  React.useEffect(
    function redirectHook() {
      if (!user && router.asPath.includes('/app')) {
        router.push('/sign-in');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(user)]
  );
  const value = React.useMemo(() => ({ user, setUser }), [user]);

  return (
    <AppContext.Provider value={value}>
      {/* {!loadingUser && typeof dataUser !== 'undefined' && 'me' in dataUser ? (
        // && Object.keys(user).length > 0
        children
      ) : router.pathname === '/sign-in' || router.pathname === '/' ? (
        children
      ) : (
        <Loading loading={loadingUser} />
      )} */}
      {!loadingUser ? children : <Loading loading={loadingUser} />}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
