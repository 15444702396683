/* eslint-disable no-nested-ternary */
const DEV_ENDPOINT = `http://localhost:5555/graphql`;
const PROD_ENDPOINT = `https://api.maxipetonline.com/graphql`;

export const ENDPOINT =
  typeof window === 'undefined' // Server-side check
    ? process.env.NODE_ENV === 'development'
      ? DEV_ENDPOINT
      : PROD_ENDPOINT
    : process.env.NEXT_PUBLIC_API_ENDPOINT ?? PROD_ENDPOINT;
