import gql from 'graphql-tag';

export const CURRENT_CONFIG = gql`
  query CURRENT_CONFIG {
    categories(filter: { active: true }) {
      _id
      name
    }
    currentSetting {
      _id
      carouselImages {
        title
        description
        url
      }
      loginImages {
        title
        description
        url
      }
      promotions {
        title
        description
        url
      }
      notice {
        text
        active
      }
      banners {
        section
        url
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      name
      email
      _id
      privilege
      token
    }
  }
`;

// ABOUT US
export const GET_ABOUT_US = gql`
  query GET_ABOUT_US {
    aboutUs {
      _id
      text
    }
  }
`;

// BRAND
export const GET_BRAND = gql`
  query GET_BRAND($filter: FilterFindOneBrandInput!) {
    brand(filter: $filter) {
      _id
      name
      banner
      logo
      priority
      active
      _id
    }
  }
`;

export const GET_BRANDS = gql`
  query GET_BRANDS($skip: Int, $limit: Int) {
    brands(skip: $skip, limit: $limit) {
      _id
      name
      logo
      active
    }
  }
`;

// CATEGORY
export const GET_CATEGORY = gql`
  query GET_CATEGORY($filter: FilterFindOneCategoryInput!) {
    category(filter: $filter) {
      _id
      name
      photo
      active
      products {
        title
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($skip: Int, $limit: Int) {
    categories(skip: $skip, limit: $limit) {
      _id
      name
      active
    }
  }
`;

// CLIENT
export const GET_CLIENT = gql`
  query GET_CLIENT($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      _id
      name
      email
      dni
      dniType
      client {
        _id
        phone
        orders {
          _id
        }
        appointments {
          _id
        }
        pets {
          _id
        }
        address {
          _id
          country
          state
          municipality
          street
        }
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query GET_CLIENTS($skip: Int, $limit: Int) {
    clients(skip: $skip, limit: $limit) {
      _id
      phone
      createdAt
      user {
        name
        active
        email
        _id
      }
      shopCart {
        _id
      }
      orders {
        _id
      }
    }
  }
`;

// CURRENCY
export const GET_CURRENCY = gql`
  query GET_CURRENCY($filter: FilterFindOneCurrencyInput!) {
    currency(filter: $filter) {
      _id
      name
      symbol
      rate
      active
    }
  }
`;

export const GET_CURRENCIES = gql`
  query GET_CURRENCIES($skip: Int, $limit: Int) {
    currencies(skip: $skip, limit: $limit) {
      _id
      name
      symbol
      active
    }
  }
`;

// DELIVERY NOTE
export const GET_DELIVERY_NOTE = gql`
  query GET_DELIVERY_NOTE($filter: FilterFindOneDeliveryNoteInput!) {
    currencies(filter: { active: true }) {
      _id
      name
      rate
    }
    deliveryNote(filter: $filter) {
      _id
      controlNumber
      paid
      generateBill
      bill {
        controlNumber
      }
      charges {
        ref
        method
        bank
        amount
      }
      paymentMetadata
      createdAt
      order {
        _id
        code
        subtotal
        extraFees
        discount
        address {
          municipality
          street
          state
        }
        taxes {
          name
          value
        }
        total
        commission
        client {
          name
          email
          client {
            address {
              municipality
              street
              state
            }
            phone
          }
        }
        location {
          name
        }
        products {
          title
          quantity
          price
          variant1
          variant2
          variant3
          photo
          location {
            name
          }
          volumeSales {
            discount
            discountType
            volume
          }
        }
        rider {
          _id
        }
      }
    }
  }
`;

export const GET_DELIVERY_NOTES = gql`
  query GET_DELIVERY_NOTES($skip: Int, $limit: Int) {
    deliveryNotes(skip: $skip, limit: $limit) {
      _id
      controlNumber
      paid
      createdAt
    }
  }
`;

// LOCATION
export const GET_LOCATION = gql`
  query GET_LOCATION($filter: FilterFindOneLocationInput!) {
    shippings(filter: { active: true }) {
      _id
      name
    }
    location(filter: $filter) {
      _id
      name
      address
      lat
      lon
      active
      shippingOptions {
        _id
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS($skip: Int, $limit: Int) {
    locations(skip: $skip, limit: $limit) {
      _id
      name
      active
    }
  }
`;

export const GET_LOCATION_RELATIONS = gql`
  query GET_LOCATION_RELATIONS {
    shippings(filter: { active: true }) {
      _id
      name
    }
  }
`;

// USER
export const GET_USER = gql`
  query GET_USER($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      name
      email
      _id
      active
      commission
      privilege
      dni
      dniType
      location {
        _id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS($skip: Int, $limit: Int, $filter: FilterFindManyUserInput) {
    users(skip: $skip, limit: $limit, filter: $filter) {
      name
      email
      _id
      active
      privilege
      location {
        _id
        name
      }
    }
  }
`;

// PRODUCT
export const GET_PRODUCT = gql`
  query GET_PRODUCT($filter: FilterFindOneProductInput!) {
    locations(filter: { _id: "61571a344868f05d4a403bb0" }) {
      _id
      name
    }
    brands(filter: { active: true }, limit: 1000000) {
      _id
      name
    }
    categories(filter: { active: true }) {
      _id
      name
    }
    subcategories(filter: { active: true }, limit: 1000000) {
      _id
      name
      categories {
        _id
        name
      }
    }
    taxes(filter: { active: true }) {
      _id
      name
    }
    product(filter: $filter) {
      _id
      title
      description
      ingredients
      instructions
      sku
      priority
      isService
      volatileInventory
      active
      photos
      price
      compareAtPrice
      tags
      frequency
      extraInfo {
        _id
        name
        value
      }
      nutrition {
        name
        value
      }
      brand {
        _id
        name
      }
      model {
        _id
        name
      }
      variants {
        _id
        title
        tags
      }
      variantValues {
        _id
        value {
          variant1
          variant2
          variant3
        }
        price
        frequency
        compareAtPrice
        quantity
        sku
        location {
          _id
          name
        }
        disabled
      }
      categories {
        _id
        name
      }
      subcategories {
        _id
        name
      }
      taxes {
        _id
        name
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($filter: FilterFindManyProductInput, $limit: Int) {
    products(filter: $filter, limit: $limit) {
      _id
      title
      active
      isService
      photos
      brand {
        _id
        name
      }
      categories {
        _id
        name
      }
      variantValues {
        _id
        value {
          variant1
          variant2
          variant3
        }
        location {
          _id
          name
        }
        photo
        sku
        price
        compareAtPrice
        quantity
        disabled
      }
      taxes {
        name
        value
      }
      volumeSales {
        discount
        discountType
        volume
      }
    }
  }
`;

export const GET_CREATE_PRODUCT_INFO = gql`
  query GET_CREATE_PRODUCT_INFO {
    locations(filter: { _id: "61571a344868f05d4a403bb0" }) {
      _id
      name
    }
    brands(filter: { active: true }) {
      _id
      name
    }
    categories(filter: { active: true }, limit: 100000) {
      _id
      name
    }
    subcategories(limit: 100000) {
      _id
      name
      categories {
        _id
        name
      }
    }
    taxes(filter: { active: true }) {
      _id
      name
    }
  }
`;

// VARIANT VALUE
export const GET_VARIANT_VALUE = gql`
  query GET_VARIANT_VALUE($variantValueID: MongoID!, $productID: MongoID!) {
    variantValue(filter: { _id: $variantValueID }) {
      value {
        variant1
        variant2
        variant3
      }
      price
      frequency
      compareAtPrice
      quantity
      photo
      location {
        _id
        name
      }
      sku
      disabled
      _id
    }
    product(filter: { _id: $productID }) {
      title
      photos
      _id
      variants {
        _id
        title
        tags
      }
      variantValues {
        _id
        value {
          variant1
          variant2
          variant3
        }
        price
        frequency
        compareAtPrice
        quantity
        sku
        location {
          _id
          name
        }
        disabled
        photo
      }
      extraInfo {
        _id
        name
        value
      }
    }
  }
`;

export const GET_VARIANT_VALUES = gql`
  query GET_VARIANT_VALUES {
    variantValues {
      _id
      value {
        variant1
        variant2
        variant3
      }
      price
      compareAtPrice
      quantity
      disabled
      product {
        _id
        title
      }
    }
  }
`;

// ORDER
export const GET_ORDER = gql`
  query GET_ORDER($data: GetOrderInput!) {
    currencies(filter: { active: true }) {
      _id
      name
    }
    locations(filter: { active: true }) {
      _id
      name
    }
    getOrder(data: $data) {
      order {
        _id
        code
        status
        paid
        subtotal
        taxes {
          name
          value
        }
        discount
        extraFees
        total
        commission
        phone
        location {
          name
        }
        products {
          _id
          title
          photo
          variant1
          variant2
          variant3
          price
          quantity
          product {
            _id
          }
          variantValue {
            _id
            quantity
          }
          location {
            _id
            name
          }
          shopCart {
            _id
          }
          volumeSales {
            discount
            discountType
            volume
          }
          taxes {
            name
            value
          }
        }
        client {
          name
          email
        }
        seller {
          name
        }
        rider {
          _id
          name
        }
        shipping {
          slug
        }
        charges {
          ref
          bank
          method
          amount
          createdAt
        }
        notes {
          _id
          fromClient
          text
        }
        chargesNotes {
          _id
          text
        }
        history {
          _id
          message
          type
          createdAt
        }
        updatedAt
        createdAt
      }
      paymentMethods {
        _id
        title
        currency {
          rate
          symbol
        }
      }
      deliveryNoteId
      billId
    }
  }
`;

export const GET_ORDERS = gql`
  query GET_ORDERS($skip: Int, $limit: Int) {
    orders(skip: $skip, limit: $limit) {
      _id
      code
      status
      total
      createdAt
      client {
        name
      }
      location {
        name
      }
      rider {
        _id
        name
      }
      charges {
        method
      }
    }
  }
`;

export const GET_CREATE_ORDER_INFO = gql`
  query GET_CREATE_ORDER_INFO($limit: Int) {
    locations(filter: { active: true }) {
      _id
      name
    }
    products(filter: { active: true }, limit: $limit) {
      _id
      title
      active
      brand {
        name
      }
      isService
      photos
      variantValues {
        _id
        price
        compareAtPrice
        quantity
        sku
        location {
          _id
          name
        }
        value {
          variant1
          variant2
          variant3
        }
      }
    }
    clients {
      _id
      phone
      user {
        _id
        name
        email
        dni
      }
      address {
        _id
        country
        state
        municipality
        street
      }
    }
    shippings {
      _id
      name
      price
    }
    paymentMethods {
      _id
      title
      currency {
        rate
        symbol
      }
    }
  }
`;

export const GET_CLIENT_ORDERS = gql`
  query GET_CLIENT_ORDERS($data: GetClientOrdersInput!) {
    getClientOrders(data: $data) {
      _id
      client {
        name
      }
      charges {
        method
      }
      status
      total
      createdAt
    }
  }
`;

// BILL
export const GET_BILL = gql`
  query GET_BILL($filter: FilterFindOneBillInput!) {
    bill(filter: $filter) {
      _id
      controlNumber
      currency {
        name
        symbol
        rate
      }
      paid
      charges {
        ref
        method
        bank
        amount
      }
      paymentMetadata
      createdAt
      rate
      order {
        _id
        status
        subtotal
        extraFees
        discount
        taxes {
          name
          value
        }
        total
        commission
        client {
          name
          email
          client {
            address {
              municipality
              street
              state
            }
            phone
          }
        }
        address {
          country
          state
          municipality
          street
        }
        products {
          title
          quantity
          price
          variant1
          variant2
          variant3
          photo
          location {
            name
          }
          volumeSales {
            volume
            discount
            discountType
          }
        }
      }
    }
  }
`;

export const GET_BILLS = gql`
  query GET_BILLS($skip: Int, $limit: Int) {
    bills(skip: $skip, limit: $limit) {
      _id
      controlNumber
      paid
      createdAt
    }
  }
`;

// SUPPLIER
export const GET_SUPPLIER = gql`
  query GET_SUPPLIER($filter: FilterFindOneSupplierInput!) {
    products(filter: { active: true }) {
      _id
      title
    }
    supplier(filter: $filter) {
      _id
      name
      active
      products {
        _id
        title
      }
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query GET_SUPPLIERS($skip: Int, $limit: Int) {
    suppliers(skip: $skip, limit: $limit) {
      _id
      name
      active
    }
  }
`;

export const GET_CREATE_SUPPLIER_DATA = gql`
  query GET_CREATE_SUPPLIER_DATA {
    products(filter: { active: true }) {
      _id
      title
    }
  }
`;

// PROMO CODE
export const GET_PROMO_CODE = gql`
  query GET_PROMO_CODE($filter: FilterFindOnePromoCodeInput!) {
    promoCode(filter: $filter) {
      _id
      name
      code
      discount
      fixed
      percentage
      expirationDate
      active
      products {
        product
        variantValue
      }
    }
  }
`;

// PROMO
export const GET_PROMO = gql`
  query GET_PROMO($filter: FilterFindOnePromoInput!) {
    promo(filter: $filter) {
      _id
      name
      photo
      discount
      fixed
      percentage
      expirationDate
      url
      active
      products {
        product
        variantValue
      }
    }
  }
`;

export const GET_PROMOS = gql`
  query GET_PROMOS($filter: FilterFindManyPromoInput) {
    promos(filter: $filter) {
      _id
      name
      discount
      fixed
      percentage
      expirationDate
      photo
      url
      active
      products {
        product
        variantValue
      }
    }
  }
`;

export const GET_PROMO_CODES = gql`
  query GET_PROMO_CODES($skip: Int, $limit: Int) {
    promoCodes(skip: $skip, limit: $limit) {
      _id
      name
      code
      active
      fixed
      percentage
      discount
      expirationDate
    }
  }
`;

// SHIPPING
export const GET_SHIPPING = gql`
  query GET_SHIPPING($filter: FilterFindOneShippingInput!) {
    shipping(filter: $filter) {
      _id
      name
      price
      active
    }
  }
`;

export const GET_SHIPPINGS = gql`
  query GET_SHIPPINGS($skip: Int, $limit: Int) {
    shippings(skip: $skip, limit: $limit) {
      _id
      name
      active
    }
  }
`;

export const GET_PLUGINS = gql`
  query GET_PLUGINS {
    plugins {
      _id
      name
      active
      credentials {
        name
        value
        type
      }
    }
  }
`;

// TERMS OF SERVICE

export const GET_TERMS_OF_SERVICE = gql`
  query GET_TERMS_OF_SERVICE {
    termsOfService {
      _id
      text
    }
  }
`;

// PRIVACY POLICY

export const GET_PRIVACY_POLICY = gql`
  query GET_PRIVACY_POLICY {
    privacyPolicy {
      _id
      text
    }
  }
`;

// NOTIFICATION
export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS {
    getNewNotifications {
      _id
      type
      message
      adminLink
      createdAt
    }
  }
`;

export const GET_NEW_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS {
    notifications(filter: { seen: false }) {
      _id
      seen
    }
  }
`;

// QUESTION
export const GET_QUESTIONS = gql`
  query GET_QUESTIONS {
    questions {
      _id
      title
      text
      createdAt
    }
  }
`;

// PAYMENT METHOD
export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS {
    paymentMethods {
      _id
      title
      userData {
        _id
        name
        value
      }
      currency {
        _id
        name
      }
    }
    currencies {
      _id
      name
    }
  }
`;

// BEST SELLER
export const GET_BEST_SELLERS = gql`
  query GET_BEST_SELLERS {
    bestSellersAdmin {
      _id
      active
      products {
        _id
      }
    }
    products(filter: { active: true }, limit: 10000) {
      _id
      title
      active
      brand {
        name
      }
      isService
      photos
      variantValues {
        _id
      }
    }
  }
`;

// TAX
export const GET_TAX = gql`
  query GET_TAX {
    tax {
      _id
      name
      active
      value
      createdAt
    }
  }
`;

export const GET_TAXES = gql`
  query GET_TAXES {
    taxes {
      _id
      name
      active
      value
      createdAt
    }
  }
`;

// VOLUME SALE
export const GET_VOLUME_SALE = gql`
  query GET_VOLUME_SALE($data: GetVolumeSaleInput!) {
    getVolumeSale(data: $data) {
      volumeSale {
        _id
        active
        name
        discount
        discountType
        volume
        createdAt
        updatedAt
      }
      linkedProducts {
        _id
        title
      }
    }
    products {
      _id
      title
    }
  }
`;

export const GET_VOLUME_SALES = gql`
  query GET_VOLUME_SALES {
    volumeSales {
      _id
      active
      name
      createdAt
      updatedAt
    }
  }
`;

// DASHBOARD
export const GET_ORDERS_DASHBOARD = gql`
  query GET_ORDERS_DASHBOARD($data: OrderDashboardInput) {
    ordersDashboard(data: $data) {
      sales {
        labels
        data
      }
      categoriesIncome {
        labels
        data
      }
      productsIncome {
        labels
        data
      }
      shippingData {
        labels
        data
      }
    }
  }
`;

export const GET_GENERAL_ORDERS_DASHBOARD = gql`
  query GET_GENERAL_ORDERS_DASHBOARD {
    generalStats {
      usersCount
      usersGrowth
      ordersCount
      ordersGrowth
      income
      incomeGrowth
      productsCount
      productsGrowth
    }
  }
`;

export const GET_PRODUCTS_DASHBOARD = gql`
  query GET_PRODUCTS_DASHBOARD($data: ProductsDashboardInput) {
    productsDashboard(data: $data) {
      productSales {
        labels
        data
      }
      productClients {
        labels
        data
      }
      totalIncome
      totalClients
    }
  }
`;

export const GET_DAILY_STATS = gql`
  query GET_DAILY_STATS($data: DailyStatsInput) {
    dailyStats(data: $data) {
      totalOrders
      totalClients
      paidedOrders
      deliveredOrders
      canceledOrders
      income
      currenciesDataset {
        labels
        data
      }
      shippingDataset {
        labels
        data
      }
      salesPerHour {
        labels
        data
      }
      orders {
        _id
        code
        status
        total
        rider {
          name
        }
        location {
          name
        }
        createdAt
        client {
          name
        }
        charges {
          method
        }
      }
      appointments {
        _id
        code
        date
        hour
        minutes
        client {
          name
        }
        location {
          name
        }
        service {
          title
        }
        status
      }
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query GET_APPOINTMENTS {
    appointments {
      _id
      code
      createdAt
      date
      hour
      minutes
      start
      end
      client {
        name
      }
      location {
        name
      }
      service {
        title
      }
      status
    }
  }
`;

export const GET_APPOINTMENT = gql`
  query GET_APPOINTMENT($appointmentID: MongoID!) {
    appointment(filter: { _id: $appointmentID }) {
      _id
      code
      date
      hour
      start
      end
      minutes
      employee
      client {
        _id
        name
        email
        dni
        dniType
        client {
          phone
        }
      }
      location {
        _id
        name
      }
      service {
        _id
        title
      }
      pet {
        _id
        name
        specie
        breed
        weight
        photo
        diseases
        vaccines {
          vaccine {
            name
          }
          taken
          voucher {
            src
            alt
            type
          }
        }
      }
      history {
        _id
        message
        type
        createdAt
      }
      status
    }
    locations(filter: { active: true }) {
      _id
      name
      address
    }
    services(filter: { active: true }) {
      _id
      title
      schedule {
        name
        value
      }
      description
      slug
      active
      employees {
        name
        description
        photo
      }
      locations {
        _id
        name
      }
    }
  }
`;

export const GET_SERVICES = gql`
  query GET_SERVICES {
    services {
      _id
      title
      schedule {
        name
        value
      }
      slug
      active
    }
  }
`;

export const GET_SERVICE = gql`
  query GET_SERVICE($serviceID: MongoID!) {
    service(filter: { _id: $serviceID }) {
      title
      description
      active
      schedule {
        name
        value
      }
      slug
      employees {
        name
        description
        photo
      }
      locations {
        _id
        name
      }
    }
    locations(filter: { active: true }) {
      _id
      name
      address
    }
  }
`;

export const GET_CREATE_APPOINTMENT_INFO = gql`
  query GET_CREATE_PRODUCT_INFO {
    locations(filter: { active: true }) {
      _id
      name
    }
    services(filter: { active: true }) {
      _id
      title
      schedule {
        name
        value
      }
      description
      slug
      active
      employees {
        name
        description
        photo
      }
      locations {
        _id
        name
      }
    }
    clients {
      _id
      phone
      user {
        _id
        name
        email
        dni
      }
      pets {
        _id
        name
        slug
        breed
        weight
        specie
        vaccines {
          vaccine {
            name
          }
          taken
          voucher {
            src
            alt
            type
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_PETS = gql`
  query GET_CLIENT_PETS($data: GetClientPetsInput!) {
    getClientPets(data: $data) {
      _id
      client {
        name
      }
      name
      specie
      breed
      weight
      photo
      birthday
      instagram
      active
      vaccines {
        vaccine {
          name
        }
        taken
        voucher {
          src
          alt
          type
        }
      }
    }
  }
`;

export const GET_CLIENT_APPOINTMENTS = gql`
  query GET_CLIENT_PETS($data: GetClientAppointmentsInput!) {
    getClientAppointments(data: $data) {
      _id
      code
      date
      hour
      minutes
      client {
        name
      }
      location {
        name
      }
      service {
        title
      }
      status
    }
  }
`;

export const GET_ADS = gql`
  query GET_ADS {
    ads {
      _id
      location
      image
      url
      sponsor
      createdAt
      active
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GET_COUNTRIES {
    getCountries
  }
`;

export const GET_COUNTRIES_DASHBOARD = gql`
  query GET_COUNTRIES_DASHBOARD($data: CountriesDashboardInput) {
    countriesDashboard(data: $data) {
      totalIncome
      totalClients
      totalOrders
      totalProducts
      categoriesIncome {
        labels
        data
      }
      productsIncome {
        labels
        data
      }
    }
  }
`;

export const GET_CALCULATOR = gql`
  query GET_CALCULATOR {
    calculator(filter: { active: true }) {
      _id
      shortHair {
        breed
        price
      }
      longHair {
        breed
        price
      }
      medicatedBath {
        breed
        price
      }
      creamBath {
        breed
        price
      }
      dentalService {
        breed
        price
      }
      nailService {
        breed
        price
      }
      shearing {
        breed
        price
      }
      hairService {
        level
        price
      }
      others {
        name
        price
      }
      extraLargeFee
    }
  }
`;

// $sort: SortFindManyBookingInput = _ID_DESC
export const GET_COMMENTS = gql`
  query GET_COMMENTS {
    comments {
      _id
      title
      text
      client {
        _id
        name
      }
      createdAt
      active
    }
  }
`;

export const GET_PRODUCTS_AND_VARIANTS = gql`
  query GET_PRODUCTS_AND_VARIANTS {
    getProductsAndVariants {
      items {
        product {
          _id
          title
        }
        variantValue {
          _id
          value {
            variant1
            variant2
            variant3
          }
          price
        }
      }
    }
  }
`;

// posts

export const GET_POSTS = gql`
  query GET_POSTS($filter: GetPostsFilter) {
    getPosts(filter: $filter) {
      _id
      category {
        name
      }
      title
      subtitle
      slug
      content
      author
      coauthor
      date
      image
      authorDescription
      active
    }
  }
`;

export const GET_POST = gql`
  query GET_POST($filter: FilterFindOnePostInput) {
    post(filter: $filter) {
      _id
      category {
        _id
        name
      }
      title
      subtitle
      slug
      content
      author
      coauthor
      date
      image
      authorDescription
      active
    }
  }
`;

export const GET_POST_CATEGORIES = gql`
  query GET_POST_CATEGORIES($filter: GetPostCategoriesFilter) {
    getPostCategories(filter: $filter) {
      _id
      name
      slug
      active
    }
  }
`;

export const GET_POST_CATEGORY = gql`
  query GET_POST_CATEGORY($filter: FilterFindOnePostCategoryInput) {
    postCategory(filter: $filter) {
      _id
      name
      slug
      active
    }
  }
`;

//  VACCINES

export const GET_VACCINES = gql`
  query GET_VACCINES($filter: FilterFindManyVaccineInput!) {
    vaccines(filter: $filter) {
      _id
      name
      createdAt
      updatedAt
      active
    }
  }
`;

//  WEEKLY SCHEDULES

export const GET_WEEKLY_SCHEDULES = gql`
  query GET_WEEKLY_SCHEDULES {
    getAllWeeklySchedules {
      _id
      start
      end
      active
      location {
        _id
        name
      }
      employees {
        user {
          name
        }
      }
    }
  }
`;
